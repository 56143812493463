export enum AreaType {
    /**
     * 普通区
     */
    NormalArea = "NormalArea",
    /**
     * 新商品区
     */
    NewArrivals = "NewArrivals",
    /**
     * 商品池区
     */
    BestSellers = "BestSellers",
    /**
     * 混装商品区
     */
    MixProducts = "MixProducts",
    /**
     * 促销商品区
     */
    Promotion = "Promotion",
    /**
     * 成品商品区
     */
    Jewelry = "Jewelry",
    /**
     * 搜索结果区
     */
    SearchArea = "SearchArea",
    /**
     * Daily Deals区
     */
    DailyDeals = "DailyDeals",
    /**
     * 相似商品
     */
    SimilarItem = "SimilarItem",
    /**
     * 替代品
     */
    Remplacement = "Remplacement",
    /**
     * 圣诞节(11)
     */
    Christmas = "Christmas",
    /**
     * 电子画册
     */
    OnlineCatalog = "OnlineCatalog",
    /**
     * Learning Center
     */
    LearningCenter = "LearningCenter",
    /**
     * 定价专区(5元专区)(14)
     */
    FixedPriceDeals = "FixedPriceDeals",
    /**
     * 属性商品（15）
     */
    PropertyProducts = "PropertyProducts",
    /**
     * 替代品相似商品
     */
    Remplacement_SimilarItem = "Remplacement_SimilarItem",
    /**
     * 清仓商品
     */
    Closeout = "Closeout",
    /**
     * 阿尔迪
     */
    SupplierSupport = "SupplierSupport",
    /**
     * wishList替代品
     */
    Remplacement_WishList = "Remplacement_WishList",
    /**
     * Small Lot小批量
     */
    Small_Lot = "Small_Lot",
    /**
     * 海外仓 美国、英国仓商品
     */
    Us_Stock = "Us_Stock",
    Uk_Stock = "Uk_Stock",
    /**
     * 商品详细页
     */
    Product_Detail = "Product_Detail",
    Sales = "Sales",
    /**
     * 替代品挑选，增加 Find in My Shopping Cart
     */
    Remplacement_In_ShoppingCart = "Remplacement_In_ShoppingCart",
    /**
     * 高级包装商品
     */
    SeniorPackaging = "SeniorPackaging",
    /**
     * 定价专区(3元专区)(27)
     */
    FixedPriceDeals3 = "FixedPriceDeals3",
    /**
     * ranking商品区
     */
    RankingProducts = "RankingProducts",
    /**
     * 优选类别商品列表
     */
    Categories = "Categories",
    /**
     * 优选新品列表
     */
    New = "New",
    /**
     * 优选Sale商品列表
     */
    Sale = "Sale",
    /**
     * 优选品牌商品列表
     */
    Brand = "Brand",
    /**
     * 精品区
     */
    Selection = "Selection",
    /**
     * 商品图搜区
     */
    ProductImageSearchArea = "ProductImageSearchArea",
    BulkArticle = "BulkArticle",
    /**
     * 帮助类别
     */
    HelpCategory = "HelpCategory",
    /**
     * 帮助详细
     */
    HelpDetail = "HelpDetail",
    /**
     * xyz五星评价商品
     */
    FiveStarProduct = "FiveStarProduct",
    /**
     * DiyCrafts
     */
    DiyCrafts = "DiyCrafts"
}

// 定义获取专区字符串的函数
export function getAreaTypeString(productAreaType: string): string {
    switch (productAreaType) {
        case AreaType.NewArrivals:
            return 'new-beads-wholesale';
        case AreaType.BestSellers:
            return 'best-seller';
        case AreaType.MixProducts:
            return 'mix-beads-wholesale';
        case AreaType.Promotion:
            return 'promotion';
        case AreaType.Jewelry:
            return 'jewelry-gift';
        case AreaType.DailyDeals:
            return 'beads-daily-deals';
        case AreaType.SimilarItem:
            return 'similar';
        case AreaType.Closeout:
            return 'clearance';
        case AreaType.SupplierSupport:
            return 'beads-smart-bargains';
        case AreaType.FixedPriceDeals:
            return 'under5-beads-wholesale';
        case AreaType.Small_Lot:
            return 'small-lot';
        case AreaType.Us_Stock:
            return 'us-hot-seller';
        case AreaType.Uk_Stock:
            return 'uk-hot-seller';
        case AreaType.SeniorPackaging:
            return 'seniorpackaging';
        case AreaType.Selection:
            return 'bontique';
        default:
            return '';
    }
}

export function getSpecialAreaDisplayName(productAreaType: string): string {
    if (productAreaType === undefined || productAreaType === null) return "";
    switch (productAreaType) {
        case AreaType.BestSellers:
            return "Best Sellers";
        case AreaType.Jewelry:
            return "PH Jewelry";
        case AreaType.MixProducts:
            return "Mixed Products";
        case AreaType.NewArrivals:
            return "New Arrivals";
        case AreaType.Promotion:
            return "Promotion";
        case AreaType.DailyDeals:
            return "Daily Deals";
        case AreaType.SupplierSupport:
            return "Smart Bargains";
        case AreaType.SimilarItem:
            return "Similar Items";
        case AreaType.FixedPriceDeals:
            return "$5 Sale";
        case AreaType.Closeout:
            return "Clearance";
        case AreaType.Small_Lot:
            return "Small Lot";
        case AreaType.NormalArea:
            return "Product Categories";
        case AreaType.Us_Stock:
            return "US Hot Seller";
        case AreaType.Uk_Stock:
            return "UK Hot Seller";
        case AreaType.SeniorPackaging:
            return "cobeads senior packaging items";
        case AreaType.Selection:
            return "Boutique";
        default:
            return productAreaType.toString();
    }
}